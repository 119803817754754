// import promocodes from '../../api/promocodesList.json'
import mytext from "../../api/vypysky"
import clientList from "../../api/jur-clients.json"
import wbRegionSaleList from "../../api/wb-region-sales.json"
import date from 'date-and-time';
import { outSourceManager, wbOutSourceMarketList } from "../constants/data";


// export const prepareOrderRowHelper = (itemList, cityList, areaList, managerList) => {
//     const local = 'ua'

//     let newOrderList = []

//     // const promocodeList = promocodes

//     itemList.forEach(order => {
//         if (order.stat_status === 3) {
//             const doctorIndex = promocodeList.findIndex(item => item.promocode === order.coupon_code)
//             const area = cityList[order.delivery_city] || "AREA_ERROR"
//             // const managerName = managerList[area] || "MANAGER_ERROR"
//             const managerName = doctorIndex !== -1 ? promocodeList[doctorIndex].manager : areaList[area]
//             // return
//             const orderUA = {
//                 orderId: order.order_id,
//                 city: order.delivery_city,
//                 products: order.products,
//                 orderCreated: order.stat_created,
//                 promocode: order.coupon_code,
//                 region: area,
//                 manager: managerName,
//                 couponPercent: order.coupon_percent,
//                 clientName: order.delivery_name,
//                 clientEmail: order.delivery_email,
//                 clientPhone: order.delivery_phone,
//             }

//             const orderRU = {
//                 orderId: null,
//                 city: '',
//                 products: {},
//                 orderCreated: '',
//                 promocode: '',
//                 region: '',
//                 manager: '',
//                 couponPercent: null,
//                 clientName: '',
//                 clientEmail: '',
//                 clientPhone: null,
//             }

//             switch (local) {
//                 case 'ua':
//                     newOrderList.push(orderUA)
//                     break
//                 case 'ru':
//                     newOrderList.push(orderRU)
//                 default:
//                     return
//             }
//         }
//     })
//     return newOrderList
// }

export const prepareJuridicalOrderRowHelper = (areaList) => {
    // console.log("🚀 ~ file: prepareOrderRowHelper.js ~ line 63 ~ prepareJuridicalOrderRowHelper ~ areaList", areaList)

    const prepareFieldItems = (text) => {
        const docSectionsArr = text.split("СекцияДокумент=Платежное поручение")
        let docItemArr = []
        let docItem2 = []
        let docItem3 = []
        docSectionsArr.forEach(item => {
            const res = item.split('/n')
            docItemArr.push(res)
        });

        docItemArr.forEach(i => {
            const temp = i[0].search('ДатаСписано') != -1 ? true : false
            if (i[0].search('ДатаСписано') != -1) return
            if (i[0].search('Номер') == -1) return
            // if (i[0].search('ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "КЛИНИКА "ЛИНЛАЙН"') == -1) return
            // console.log("🚀 ~ file: UpdateDataContainer.js ~ line 77 ~ prepareFieldItems ~ temp", temp)
            const res = i[0].split('\n')
            docItem2.push(res)
        });

        docItem2.forEach(i => {
            const numberItem = typeof i[1] === 'string' ? i[1].split('=') : 'no'
            const dateItem = i[2].split('=')
            const sumItem = i[3].split('=')
            const innNumberItem = i[6].split('=')
            const cityItem = i[10].split('=')
            const senderNameItem = typeof i[5] === 'string' ? i[5].split('=') : 'no'
            const senderKPP = typeof i[29] === 'string' ? i[29].split('=') : 'no'
            docItem3.push({ numberItem, dateItem, sumItem, senderNameItem, innNumberItem, cityItem, senderKPP })
        });
        return docItem3
    }

    const docArr = prepareFieldItems(mytext)

    let newOrderList = []

    docArr.forEach(order => {
        // console.log("🚀 ~ file: prepareOrderRowHelper.js ~ line 104 ~ prepareJuridicalOrderRowHelper ~ order", order)

        const clientIndex = clientList.findIndex(item => item.inn == order.innNumberItem[1])
        const clientIndex2 = clientList.findIndex(item => item.senderKPP == order.senderKPP[1] && item.senderKPP !== '5')
        const senderKPP = clientIndex2 != -1 ? String(clientList[clientIndex2].senderKPP) : 'no senderKPP'
        const managerData = clientIndex2 != -1 ? clientList[clientIndex2] : clientList[clientIndex]
        // console.log("🚀 ~ file: prepareOrderRowHelper.js:111 ~ prepareJuridicalOrderRowHelper ~ managerData:", managerData)
        const managerId = managerData.managerId
        const region = managerData.region
        const country = managerData.country
        const shopSource = clientList[clientIndex].source
        const clientCity = clientList[clientIndex].city
        const orderMonth = order.dateItem[1].substring(3, 5)
        const orderYear = order.dateItem[1].substring(6, 10)
        const orderDay = order.dateItem[1].substring(0, 2)

        const dateCon = `${orderYear}-${orderMonth}-${orderDay}`
        const dateFormat = date.format(new Date(dateCon), 'YYYY-MM-DD')

        const orderJur = {
            orderId: `j-${order.numberItem[1]}-${order.dateItem[1]}-${clientCity.substring(0, 2)}`,
            city: clientCity,
            products: {},
            orderCreated: dateFormat,
            orderMonth: orderMonth,
            orderYear: orderYear,
            promocode: '',
            region: region,
            country: country,
            manager: managerId,
            couponPercent: null,
            clientName: order.senderNameItem[1],
            clientEmail: senderKPP,
            clientPhone: order.innNumberItem[1],
            totalPrice: Math.round(order.sumItem[1]),
            productTotalPrice: Math.round(order.sumItem[1]),
            shopSource: shopSource
        }

        newOrderList.push(orderJur)
    })
    return newOrderList
}

export const prepareWildberriesOrderRowHelper = (areaList) => {

    let newOrderList = []

    wbRegionSaleList.forEach(order => {
        if (order.crmPrice <= 0) return
        const dateCon = `${order.year}-${order.month}-10`
        const dateFormat = date.format(new Date(dateCon), 'YYYY-MM-DD')
        const orderId = order.saleId
        const orderRegionFormat = order.region.replace(/ область|Республика | край| Республика/g, '');
        const regionIndex = areaList.findIndex(item => item.region === orderRegionFormat)
        const region = orderRegionFormat
        let managerId = regionIndex >= 0 && areaList[regionIndex].manager ? areaList[regionIndex].manager.id : 14
        const ManagerIdSng = order.country === "Казахстан" ? managerId = 56 : managerId
        // const outsourceManager = wbOutSourceMarketList.some(source => order.sourceId.includes(source)) ? outSourceManager : ManagerIdSng;
        // сеньтябрь аутсорс, октябрь как было
        const products = { title: order.article, quantity: order.qty, price: order.price, sku: order.article }
        const totalSum = Math.round(order.totalSum) - (order.qty * 150)
        const orderWb = {
            orderId: orderId,
            city: order.city,
            products: products,
            productTitle: products.title,
            productQuantity: products.quantity,
            productTotalPrice: totalSum,
            productArticle: products.sku,
            orderCreated: dateFormat,
            orderMonth: order.month,
            orderYear: order.year,
            promocode: '',
            region: region,
            manager: ManagerIdSng,
            couponPercent: null,
            clientName: order.country,
            clientEmail: '',
            clientPhone: `${order.totalSum}`, //temp
            totalPrice: totalSum,
            shopSource: 'wb',
            country: order.country,
            marketId: order.sourceId
        }

        newOrderList.push(orderWb)
    })
    // console.log("🚀 ~ file: prepareOrderRowHelper.js ~ line 181 ~ prepareWildberriesOrderRowHelper ~ newOrderList", newOrderList)
    return newOrderList
}