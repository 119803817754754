 const mytext =

`
1CClientBankExchange
ВерсияФормата=1.03
Кодировка=Windows
Отправитель=СБИС
ДатаСоздания=30.10.2024
ВремяСоздания=17:55:53
ДатаНачала=30.10.2024
ДатаКонца=30.10.2024
РасчСчет=40802810138000060505
СекцияДокумент=Платежное поручение
Номер=1434
Дата=25.10.2024
Сумма=5000.00
ПлательщикСчет=40802810422240000729
Плательщик=ИП Бысова Елена Евгеньевна
ПлательщикИНН=402900836681
Плательщик1=ИП Бысова Елена Евгеньевна
ПлательщикРасчСчет=40802810422240000729
ПлательщикБанк1=КАЛУЖСКОЕ ОТДЕЛЕНИЕ N8608 ПАО СБЕРБАНК
ПлательщикБанк2=КАЛУГА
ПлательщикБИК=042908612
ПлательщикКорсчет=30101810100000000612
ПолучательСчет=40802810138000060505
ДатаПоступило=25.10.2024
Получатель=ИП Цоллер Станислав Владимирович
ПолучательИНН=561018779543
Получатель1=ИП Цоллер Станислав Владимирович
ПолучательРасчСчет=40802810138000060505
ПолучательБанк1=ПАО Сбербанк
ПолучательБанк2=Москва
ПолучательБИК=044525225
ПолучательКорсчет=30101810400000000225
КодНазПлатежа=
ВидОплаты=01
НазначениеПлатежа=Оплата по договору за косметику по счетам 2023г.. Сумма 5000-00 Без налога (НДС)
НазначениеПлатежа1=Оплата по договору за косметику по счетам 2023г.. Сумма 5000-00 Без налога (НДС)
Очередность=5
КонецДокумента
СекцияДокумент=Платежное поручение
Номер=1416
Дата=24.10.2024
Сумма=5000.00
ПлательщикСчет=40802810422240000729
Плательщик=ИП Бысова Елена Евгеньевна
ПлательщикИНН=402900836681
Плательщик1=ИП Бысова Елена Евгеньевна
ПлательщикРасчСчет=40802810422240000729
ПлательщикБанк1=КАЛУЖСКОЕ ОТДЕЛЕНИЕ N8608 ПАО СБЕРБАНК
ПлательщикБанк2=КАЛУГА
ПлательщикБИК=042908612
ПлательщикКорсчет=30101810100000000612
ПолучательСчет=40802810138000060505
ДатаПоступило=24.10.2024
Получатель=ИП Цоллер Станислав Владимирович
ПолучательИНН=561018779543
Получатель1=ИП Цоллер Станислав Владимирович
ПолучательРасчСчет=40802810138000060505
ПолучательБанк1=ПАО Сбербанк
ПолучательБанк2=Москва
ПолучательБИК=044525225
ПолучательКорсчет=30101810400000000225
КодНазПлатежа=
ВидОплаты=01
НазначениеПлатежа=Оплата по договору за косметику по счетам 2023г.. Сумма 5000-00 Без налога (НДС)
НазначениеПлатежа1=Оплата по договору за косметику по счетам 2023г.. Сумма 5000-00 Без налога (НДС)
Очередность=5
КонецДокумента
СекцияДокумент=Платежное поручение
Номер=9797
Дата=24.10.2024
Сумма=18627.00
ПлательщикСчет=
Плательщик=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ОНИКС"
ПлательщикИНН=6168118596
Плательщик1=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ОНИКС"
ПлательщикРасчСчет=
ПлательщикБанк1=
ПлательщикБанк2=
ПлательщикБИК=
ПлательщикКорсчет=
ПолучательСчет=40802810138000060505
ДатаПоступило=24.10.2024
Получатель=ИП Цоллер Станислав Владимирович
ПолучательИНН=561018779543
Получатель1=ИП Цоллер Станислав Владимирович
ПолучательРасчСчет=40802810138000060505
ПолучательБанк1=ПАО Сбербанк
ПолучательБанк2=Москва
ПолучательБИК=044525225
ПолучательКорсчет=30101810400000000225
КодНазПлатежа=
ВидОплаты=01
Код=0
НазначениеПлатежа=ОПЛАТА ПО АКТУ СВЕРКИ ОТ 23.10 2024 ЗА МЕД. ТОВАР СУММА 18627-00 БЕЗ НАЛОГА (НДС)
НазначениеПлатежа1=ОПЛАТА ПО АКТУ СВЕРКИ ОТ 23.10 2024 ЗА МЕД. ТОВАР СУММА 18627-00 БЕЗ НАЛОГА (НДС)
ПлательщикКПП=616801001
Очередность=5
КонецДокумента
СекцияДокумент=Платежное поручение
Номер=1265
Дата=23.10.2024
Сумма=10209.00
ПлательщикСчет=40702810209400308915
Плательщик=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ГРААЛЬ"
ПлательщикИНН=9403015327
Плательщик1=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ГРААЛЬ"
ПлательщикРасчСчет=40702810209400308915
ПлательщикБанк1=ПАО "Промсвязьбанк"
ПлательщикБанк2=МОСКВА
ПлательщикБИК=044525555
ПлательщикКорсчет=30101810400000000555
ПолучательСчет=40802810138000060505
ДатаПоступило=23.10.2024
Получатель=ИП Цоллер Станислав Владимирович
ПолучательИНН=561018779543
Получатель1=ИП Цоллер Станислав Владимирович
ПолучательРасчСчет=40802810138000060505
ПолучательБанк1=ПАО Сбербанк
ПолучательБанк2=Москва
ПолучательБИК=044525225
ПолучательКорсчет=30101810400000000225
КодНазПлатежа=
ВидОплаты=01
Код=0
НазначениеПлатежа=Платеж по договору № 2427, от  03.08.2023г. НДС не предусмотрен
НазначениеПлатежа1=Платеж по договору № 2427, от  03.08.2023г. НДС не предусмотрен
ПлательщикКПП=940301001
Очередность=5
КонецДокумента
СекцияДокумент=Платежное поручение
Номер=216
Дата=29.10.2024
Сумма=25100.00
ПлательщикСчет=40702810160090002021
Плательщик=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ЭФФИ"
ПлательщикИНН=2460246208
Плательщик1=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ЭФФИ"
ПлательщикРасчСчет=40702810160090002021
ПлательщикБанк1=СИБИРСКИЙ ФИЛИАЛ АО "БАНК ИНТЕЗА"
ПлательщикБанк2=НОВОСИБИРСК
ПлательщикБИК=045004883
ПлательщикКорсчет=30101810250040000883
ПолучательСчет=40802810138000060505
ДатаПоступило=29.10.2024
Получатель=ИП Цоллер Станислав Владимирович
ПолучательИНН=561018779543
Получатель1=ИП Цоллер Станислав Владимирович
ПолучательРасчСчет=40802810138000060505
ПолучательБанк1=ПАО Сбербанк
ПолучательБанк2=Москва
ПолучательБИК=044525225
ПолучательКорсчет=30101810400000000225
КодНазПлатежа=
ВидОплаты=01
Код=0
НазначениеПлатежа=оплата за косметич товары по счету  7618 от 25.10.2024, НДС не облагается
НазначениеПлатежа1=оплата за косметич товары по счету  7618 от 25.10.2024, НДС не облагается
ПлательщикКПП=246001001
Очередность=5
КонецДокумента
СекцияДокумент=Платежное поручение
Номер=811
Дата=29.10.2024
Сумма=1471.00
ПлательщикСчет=40702810413660007793
Плательщик=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "МЕДИЦИНСКИЙ ЦЕНТР "ЛАЗЕР"
ПлательщикИНН=3250073832
Плательщик1=ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "МЕДИЦИНСКИЙ ЦЕНТР "ЛАЗЕР"
ПлательщикРасчСчет=40702810413660007793
ПлательщикБанк1=Филиал "Центральный" Банка ВТБ (ПАО)
ПлательщикБанк2=Москва
ПлательщикБИК=044525411
ПлательщикКорсчет=30101810145250000411
ПолучательСчет=40802810138000060505
ДатаПоступило=29.10.2024
Получатель=ИП Цоллер Станислав Владимирович
ПолучательИНН=561018779543
Получатель1=ИП Цоллер Станислав Владимирович
ПолучательРасчСчет=40802810138000060505
ПолучательБанк1=ПАО Сбербанк
ПолучательБанк2=Москва
ПолучательБИК=044525225
ПолучательКорсчет=30101810400000000225
КодНазПлатежа=
ВидОплаты=01
Код=0
НазначениеПлатежа=ОПЛАТА ПО ДОГОВОРУ №2406 ОТ 31.01.2023 ГОДА, ЗА КОСМЕТОЛОГИЧЕСКУЮ ПРОДУКЦИЮ (2 ПОЗ.), НДС НЕ ОБЛАГАЕТСЯ
НазначениеПлатежа1=ОПЛАТА ПО ДОГОВОРУ №2406 ОТ 31.01.2023 ГОДА, ЗА КОСМЕТОЛОГИЧЕСКУЮ ПРОДУКЦИЮ (2 ПОЗ.), НДС НЕ ОБЛАГАЕТСЯ
ПлательщикКПП=325701001
Очередность=5
КонецДокумента
КонецФайла
`

export default mytext